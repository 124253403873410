
import { Options, Vue } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'

@Options({})
export default class layout extends Vue {
  @Action login: any
  @Getter openid: any

  mounted() {
    if (!this.openid) {
      this.login()
    }
  }
}
