import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "con-box" }
const _hoisted_2 = {
  key: 0,
  class: "con-footer",
  ref: "boxFoot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["box-scroll", { 'is-footer': _ctx.isFooter }])
    }, [
      _createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.className)
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ]),
        _: 3
      })
    ], 2),
    (_ctx.isFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "footer")
        ], 512))
      : _createCommentVNode("", true)
  ]))
}