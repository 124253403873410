import './public-path'
import axios from './plugins/axios'
import { formatTime, mapJson } from './plugins/util'
import ConBox from '@/components/ConBox.vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import TEditor from '@/components/TEditor.vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'


interface axios {
    post: any,
    get: any,
}

const app = createApp(App)
app.config.globalProperties.$formatTime = formatTime
app.config.globalProperties.mapJson = mapJson
app.config.globalProperties.$axios = axios
app.component('TEditor',TEditor)
app.component('con-box', ConBox).use(ElementUI, {
    locale: zhCn,
}).use(store).use(router).mount('#app')
