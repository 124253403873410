import axios from 'axios'
import qs from 'qs'

const w: any = window
axios.defaults.baseURL = w.microApp?.getData().baseURL || 'https://recover.0xiang.cn'
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('w7-token')
        if (token) {
            config.headers['admin-token'] = token
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        return config
    }
)

axios.interceptors.response.use(
    response => Promise.resolve(response),
    error => {
        if (error && error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('w7-token')
                    break
            }
        }
    }
)

export default {
    post(url: any, data: any) {
        return new Promise((resolve: any, reject: any) => {
            axios({
                method: 'post',
                url: axios.defaults.baseURL + url,
                data: qs.stringify(data)
            })
                .then(res=> {
                    resolve(res.data)
                })
                .catch(err=> {
                    reject(err)
                })
        })
    },
    get(url: any, data: any) {
        return new Promise((resolve: any, reject: any) => {
            axios({
                method: 'get',
                url: axios.defaults.baseURL + url,
                data: data
            })
                .then(res=> {
                    resolve(res.data)
                })
                .catch(err=> {
                    reject(err)
                })
        })
    }
}
