
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    className: String,
    isFooter: {
      default: false,
      type: Boolean
    }
  }
})
export default class PriceIndex extends Vue {
  className: string
  isFooter: boolean

  get footHeight() {
    let f: any = this.$refs.boxFoot
    return f ? f.offsetHeight : 0
  }
}
