import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/default.vue'
declare global {
  interface Window { __MICRO_APP_BASE_ROUTE__: any }
}
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Layout
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: Layout,
    children: [
      {
        path: 'lunbo',
        component: () => import('@/views/home/lunbo/index.vue')
      },
      {
        path: 'type',
        component: () => import('@/views/home/type/index.vue')
      },
      {
        path: 'price',
        component: () => import('@/views/home/price/index.vue')
      },
      {
        path: 'rule',
        component: () => import('@/views/home/rule/index.vue')
      },
      {
        path: 'pages',
        component: () => import('@/views/home/pages/index.vue')
      }
    ]
  },
  {
    path: '/staff',
    name: 'staff',
    meta: {
      title: '回收员管理'
    },
    component: Layout,
    children: [
      {
        path: 'admin',
        component: () => import('@/views/staff/admin/index.vue')
      },
      {
        path: 'admin/detail',
        component: () => import('@/views/staff/admin/detail.vue')
      },
      {
        path: 'examine',
        component: () => import('@/views/staff/examine.vue')
      },
      {
        path: 'log',
        component: () => import('@/views/staff/log.vue')
      }
    ]
  },
  {
    path: '/jifenshop',
    name: 'jifenshop',
    meta: {
      title: '积分商城'
    },
    component: Layout,
    children: [
      {
        path: 'setting',
        component: () => import('@/views/jifenshop/index.vue')
      },
      {
        path: 'type',
        component: () => import('@/views/jifenshop/type/index.vue')
      },
      {
        path: 'goods',
        component: () => import('@/views/jifenshop/goods/index.vue')
      },
      {
        path: 'order',
        component: () => import('@/views/jifenshop/order.vue')
      }
    ]
  },
  {
    path: '/take',
    name: 'take',
    meta: {
      title: '提现管理'
    },
    component: Layout,
    children: [
      {
        path: 'take',
        component: () => import('@/views/take/index.vue')
      }
    ]
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      title: '会员管理'
    },
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/member/index.vue')
      },
      {
        path: 'black',
        component: () => import('@/views/member/black.vue')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单管理'
    },
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/order/index.vue')
      },
      {
        path: 'cycle',
        component: () => import('@/views/order/cycle.vue')
      }
    ]
  },
  {
    path: '/baseset',
    name: 'baseset',
    meta: {
      title: '设置管理'
    },
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/baseset/index.vue')
      },
      {
        path: 'apply',
        component: () => import('@/views/baseset/apply.vue')
      },
      {
        path: 'about',
        component: () => import('@/views/baseset/about/index.vue')
      },
      {
        path: 'about/detail',
        component: () => import('@/views/baseset/about/detail.vue')
      },
      {
        path: 'news',
        component: () => import('@/views/baseset/news/index.vue')
      },
      {
        path: 'news/detail',
        component: () => import('@/views/baseset/news/detail.vue')
      },
      {
        path: 'money',
        component: () => import('@/views/baseset/money/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(window.__MICRO_APP_BASE_ROUTE__ || process.env.BASE_URL),
  routes
})

export default router
