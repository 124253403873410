import { createStore } from 'vuex'

export default createStore({
  state: {
    openid: localStorage.getItem('w7-token')
  },
  getters: {
    openid (state: any) {
      return state.openid
    }
  },
  mutations: {
    SET_USER (state: any, data: string) {
      localStorage.setItem('w7-token', data)
      if (!data) {
        localStorage.removeItem('w7-token')
      }
    }
  },
  actions: {
    async login ({ commit }: any, vm: any) {
      const w: any = window
      if (w.microApp && w.microApp.getData) {
        const { code } = await w.microApp.getData().login().then()
        const { message, status, data } = await vm.$axios.post('/api/admin/auth', {
          code
        })
        if (status) {
          commit('SET_USER', data.openid)
        }
        return {
          status,
          message
        }
      } else {
        return {
          status: false,
          message: '这不是微擎环境'
        }
      }
    }
  },
  modules: {
  }
})
