<template>
  <router-view/>
</template>

<style lang="scss">
@import "assets/scss/color";
@import "assets/scss/icon";
@import "assets/scss/public";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
